


















import Vue from 'vue'

import MessageBus from '@/message-bus'
import log from '@/log'

import { urlBase64ToUint8Array } from '@/services/webpush'

const PUBLIC_KEY = process.env.VUE_APP_WEBPUSH_PUBLIC_KEY

export default Vue.extend({
  metaInfo: {
    title: 'Webpush',
  },
  methods: {
    requestPermission() {
      Notification.requestPermission().then(result => {
        if (result === 'denied') {
          MessageBus.error('Permission: ' + result)
        } else {
          MessageBus.success('Permission: ' + result)
        }
      })
    },
    subscribeUserToPush() {
      return navigator.serviceWorker
        .register('/service-worker.js')
        .then(registration => {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
          }

          return registration.pushManager.subscribe(subscribeOptions)
        })
        .then(pushSubscription => {
          log.info(
            'Received PushSubscription: ',
            JSON.stringify(pushSubscription)
          )

          return this.$api.webpush.subscribe(pushSubscription)
        })
        .then(() => {
          MessageBus.success('Subscribed: success!')
        })
        .catch(err => {
          log.error(err)
          MessageBus.error(err)
        })
    },
  },
})
